import AppMenuItem from "./AppMenuItem";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Styles } from "../../Styles";
import { Divider } from "@mui/material";
import { menuItemsData } from "../../constants";

const AppMenu = () => {
  return (
    <div className={"app-menu"}>
      {menuItemsData.map((itemData, i) => (
        <AppMenuItem
          key={i}
          itemTitle={itemData.itemTitle}
          subItems={itemData.subItems}
        />
      ))}
    </div>
  );
};

export default AppMenu;
