import React from "react";
import { Modal, Box } from "@mui/material";
import { Styles } from "../../Styles";

const StartModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ border: "none", outline: "none" }} onClick={handleClose}>
        <Box
          sx={{
            width: 200,
            height: 200,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(124, 255, 117, 0.5)",
            outline: "none",
          }}
        >
          <h2 style={{ color: Styles.color, textAlign: "center" }}>
            Click to Continue
          </h2>
        </Box>
      </div>
    </Modal>
  );
};

export default StartModal;
