import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Styles } from "../../../Styles";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: Styles.color,
    background: Styles.colorRgba,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: Styles.colorRgba,
      },
    },
  },
}));

const subItemsDefault = [
  {
    title: "Sub Item 1",
    url: "#",
    icon: EditIcon,
    newTab: false,
  },
  {
    title: "Sub Item 2",
    url: "#",
    icon: EditIcon,
    newTab: false,
  },
];

export default function AppMenuItem({
  itemTitle = "Default Title",
  subItems = subItemsDefault,
}) {
  const [anchorEl, setAnchorEl] = React.useState();
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (subItem) => {
    return (e) => {
      if (subItem.newTab) {
        window.open(subItem.url);
      } else {
        window.location = subItem.url;
      }
      handleClose();
    };
  };

  return (
    <div className="app-menu-item">
      <button
        className={"btn-menu-item" + (open ? " active" : "")}
        id="demo-customized-button"
        aria-owns={anchorEl ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        // aria-expanded={open ? "true" : undefined}
        // variant="contained"
        // disableElevation
        onMouseOver={handleOpen}
      >
        <span>{itemTitle}</span>
        <KeyboardArrowDownIcon />
      </button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          // "aria-labelledby": "demo-customized-button",
          onMouseLeave: handleClose,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {subItems.map((subItem, idx) => (
          <React.Fragment key={idx}>
            <MenuItem
              onClick={handleClick(subItem)}
              disableRipple
              style={{
                color: Styles.color,
                fontFamily: Styles.fontFamily,
                fontWeight: "bold",
              }}
              onMouseEnter={(e) => (e.target.style.color = "#fff")}
              onMouseLeave={(e) => (e.target.style.color = Styles.color)}
            >
              <subItem.icon style={{ marginRight: 8 }} />
              {subItem.title}
            </MenuItem>
            {idx < subItems?.length - 1 && <Divider sx={{ my: 0.5 }} />}
          </React.Fragment>
        ))}
      </StyledMenu>
    </div>
  );
}
