import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaEthereum } from "react-icons/fa";

export const menuItemsData = [
  {
    itemTitle: "About",
    subItems: [
      {
        title: "Telegram",
        url: "https://t.me/peboyz",
        icon: FaTelegram,
        newTab: true,
      },
      {
        title: "Twitter",
        url: "https://twitter.com/peboyerc20",
        icon: FaTwitter,
        newTab: true,
      },
    ],
  },
  {
    itemTitle: "Token Info",
    subItems: [
      {
        title: "Etherscan",
        url: "https://etherscan.io/",
        icon: FaEthereum,
        newTab: true,
      },
      {
        title: "Dex Tools",
        url: "#",
        icon: FaEthereum,
        newTab: false,
      },
    ],
  },
];
