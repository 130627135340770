import "./App.css";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";
import ParticleEffectLayout from "./effects/Particles/ParticleEffectLayout";
import HomePage from "./pages/home";

function App() {
  return (
    <div className="App">
      <AppHeader />
      <HomePage />
      <AppFooter />
      <ParticleEffectLayout />
    </div>
  );
}

export default App;
