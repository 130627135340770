import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { menuItemsData } from "../../constants";
import { Styles } from "../../Styles";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  //   ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  background: "rgba(7, 38, 6, 0.9)",
}));

export default function PersistentDrawerRight({
  open = false,
  setOpen = (open) => {},
}) {
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        // variant="persistent"
        anchor="right"
        open={open}
        onClose={() => handleDrawerClose()}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon sx={{ color: Styles.color }} />
            ) : (
              <ChevronRightIcon sx={{ color: Styles.color }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            background: "#072606",
            height: "100%",
            color: Styles.color,
          }}
        >
          {menuItemsData.map((itemData, index) => (
            <Box>
              <ListItem key={itemData.itemTitle}>
                <ListItemButton>
                  <div style={{ fontSize: 20 }}>{itemData.itemTitle}</div>
                </ListItemButton>
              </ListItem>
              <List>
                {itemData.subItems.map((subItemData, idx) => (
                  <ListItem key={subItemData.title}>
                    <ListItemButton
                      onClick={() => {
                        subItemData.newTab
                          ? window.open(subItemData.url)
                          : (window.location = subItemData.url);
                      }}
                    >
                      <ListItemIcon>{subItemData.icon}</ListItemIcon>
                      <div style={{ fontSize: 18 }}>{subItemData.title}</div>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
