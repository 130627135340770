import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { useEffect, useState } from "react";
import StartModal from "../../components/StartModel";
import { lightTheme, SwapWidget } from "@uniswap/widgets";
import "@uniswap/widgets/fonts.css";

const myLightTheme = {
  ...lightTheme, // Extend the lightTheme
  outline: "#fff",
  networkDefaultShadow: "#fff",
  deepShadow: "#fff",
  currentColor: "currentColor",
};

const HomePage = () => {
  const [openStartModel, setOpenStartModel] = useState(true);

  useEffect(() => {
    const backgroundMusicComponent =
      document.getElementById("background-music");
    backgroundMusicComponent.volume = 0.2;

    backgroundMusicComponent.play().catch((e) => {
      document.addEventListener(
        "click",
        () => {
          document.getElementById("background-music").play();
        },
        { once: true }
      );
    });

    const laughSound = document.getElementById("laugh-sound");
    laughSound.volume = 0.7;
    document.getElementById("avatar").addEventListener("mouseover", () => {
      laughSound.play();
    });
  }, []);

  return (
    <div className="home-container">
      {/* <div>
        <a
          className="btn-whitepaper blink-effect"
          href="https://scooby-doo.gitbook.io/scooby-doo/"
          target="_blank"
        ></a>
        <video
          id="menu"
          width={"100%"}
          height={"100%"}
          autoPlay
          playsInline
          loop
          muted
          style={{ objectFit: "fill" }}
        >
          <source src="ScoobyDoo.mp4" type="video/mp4" />
        </video>
      </div> */}

      <div className="intro">
        <div className="left">
          <div className="project-name">PEBOY</div>
          <div className="launchpad-title">LAUNCHPAD ON PINKSALE (SOON)</div>
          <div className="content">
            The crypto meme project Peboy is an exciting creation inspired by
            the character Joy Boy from the popular series One Piece. The
            character Joy Boy has been introduced to the meme world in the form
            of a green frog and given a new name, Peboy. This is a distinctive
            and creative concept, blending the world of crypto with the One
            Piece universe. Peboy has the potential to become an icon in the
            crypto meme community and attract the interest of investors and
            enthusiasts. Let's follow Peboy and see where this exciting journey
            will lead us!
          </div>
          <div className="socials">
            <button
              onClick={() => {
                window.open("https://t.me/peboyz", "_blank");
              }}
            >
              <FaTelegram className="social-icon" />
              <span>Telegram</span>
            </button>
            <button
              onClick={() => {
                window.open("https://twitter.com/peboyerc20", "_blank");
              }}
            >
              <FaTwitter className="social-icon" />
              <span>Twitter</span>
            </button>
          </div>
        </div>
        <div className="avatar-container">
          <div className="glow" />
          <img
            id="avatar"
            className="avatar zoom-effect"
            src="images/avatar.png"
            alt="Peboy"
          />
        </div>
      </div>
      <div className="swap-widget">
        <SwapWidget theme={myLightTheme} />
      </div>

      <audio id="background-music" loop>
        <source src="background.mp3" type="audio/mp3" />
      </audio>

      <audio id="laugh-sound">
        <source src="joyboylaugh.mp3" type="audio/mp3" />
      </audio>

      <StartModal
        open={openStartModel}
        handleClose={() => setOpenStartModel(false)}
      />
      {/* <div className="contact-container">
        <div className="contact">
          <a
            className="contact-item btn-telegram blink-effect-contact"
            href="https://t.me/ScoobyDoo_MemeChannel"
            target="_blank"
          ></a>
          <a
            className="contact-item btn-twitter blink-effect-contact"
            href="https://twitter.com/Scoobydooo_meme"
            target="_blank"
          ></a>
        </div>
      </div> */}
    </div>
  );
};

export default HomePage;
