import React from "react";

const AppFooter = () => {
  return (
    <div className="App-footer">
      <div>
        <div>10B</div>
        <div>Supply</div>
      </div>
      <div>
        <div>5%</div>
        <div>Buy Tax</div>
      </div>
      <div>
        <div>5%</div>
        <div>Sell Tax</div>
      </div>
      <div>
        <div>Locked</div>
        <div>Liquidity</div>
      </div>
    </div>
  );
};

export default AppFooter;
